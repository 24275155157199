import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';
import HttpClient from './utils/HttpClient';

interface UsersServiceInterfae {
  id?: string,
  companyName?: string,
  collection?: string,
  page?: number,
  reqBody?: ReqBodyType,
  token: string,
}

class UsersService {
  private readonly httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  listUsers = async({
    token,
  }: UsersServiceInterfae) => {
    return this.httpClient.get({
      path: '/collaborators',
      token,
    });
  };

  createMultiUsers = async({
    reqBody,
    token,
  }: UsersServiceInterfae) => {
    return this.httpClient.post({
      path: '/collaborators',
      reqBody,
      token,
    });
  };

  updateUserStatus = async({
    reqBody,
    token,
  }: UsersServiceInterfae) => {
    return this.httpClient.put({
      path: '/collaborators',
      reqBody,
      token,
    });
  };

  deleteUser = async({
    reqBody,
    token,
  }: UsersServiceInterfae) => {
    return this.httpClient.delete({
      path: '/collaborators',
      reqBody,
      token,
    });
  };

  getCanceleds = async({
    token,
  }: UsersServiceInterfae) => {
    return this.httpClient.get({
      path: '/canceleds/',
      token,
    });
  };
}

export default new UsersService();
